<template>
  <b-container>
    <b-row>
      <b-col cols="12">
        <b-card
            :header="'Skatīt dokumentu'"
            header-tag="header"
            footer-tag="footer"
            footer=" "
        >

          <b-form @submit="onSubmit" enctype="multipart/form-data">

            <div class="group-title">
              <h5>Personas dati:</h5>
            </div>

              <b-form-group
                  id="input-group-1"
                  label="Vārds:"
                  label-for="input-1"
              >

                <b-form-input v-model="form.name" disabled></b-form-input>

              </b-form-group>

              <b-form-group
                  id="input-group-2"
                  label="Uzvārds:"
                  label-for="input-2"
              >

                <b-form-input v-model="form.surname" disabled></b-form-input>

              </b-form-group>

              <b-form-group
                  id="input-group-3"
                  label="Personas kods:"
                  label-for="input-3"
              >

                <b-form-input v-model="form.personal_code" disabled></b-form-input>

              </b-form-group>

              <b-form-group
                  id="input-group-3"
                  label="Dzimšanas datums:"
                  label-for="input-3"
              >

                <b-form-input v-model="form.birthdate" type="date"  disabled></b-form-input>

              </b-form-group>

              <b-form-group
                  id="input-group-4"
                  label="Tālrunis:"
                  label-for="input-4"
              >

                <b-form-input type="text" v-model="form.phone" disabled></b-form-input>

              </b-form-group>

              <b-form-group
                  id="input-group-5"
                  label="E-pasts:"
                  label-for="input-5"
              >

                <b-form-input type="email" v-model="form.email" disabled></b-form-input>

              </b-form-group>

              <b-form-group
                  id="input-group-6"
                  label="Dzimums:"
                  label-for="input-6"
              >

                <b-form-group v-slot="{ ariaDescribedby }">
                  <b-form-radio v-model="form.gender" :aria-describedby="ariaDescribedby" value="0" disabled>sieviete</b-form-radio>
                  <b-form-radio v-model="form.gender" :aria-describedby="ariaDescribedby" value="1" disabled>vīrietis</b-form-radio>
                </b-form-group>

              </b-form-group>

              <b-form-group
                  id="input-group-1"
                  label="Līguma nr.:"
                  label-for="input-1"
              >

                <b-form-input v-model="form.agreement" disabled></b-form-input>

              </b-form-group>

              <b-form-group
                  id="input-group-2"
                  label="Līguma datums:"
                  label-for="input-2"
              >

                <b-form-input v-model="form.agreement_date" type="date" disabled></b-form-input>

              </b-form-group>

            <div class="group-title">
              <h5>Izglītība:</h5>
            </div>

              <b-form-group
                  id="input-group-1"
                  label="Izglītības veids:"
                  label-for="input-1"
                  v-if="options"
              >

                <b-form-input list="my-list-id" v-model="form.education_type" disabled></b-form-input>

                <datalist id="my-list-id">
                  <option v-for="et in options.education_types" :id="et">{{ et.text }}</option>
                </datalist>

              </b-form-group>

              <b-form-group
                  id="input-group-2"
                  label="Grāds:"
                  label-for="input-2"
                  v-if="options"
              >

                <b-form-input list="my-list-id2" v-model="form.grade" disabled></b-form-input>

                <datalist id="my-list-id2">
                  <option v-for="et in options.grades" :id="et">{{ et.text }}</option>
                </datalist>

              </b-form-group>

              <b-form-group
                  id="input-group-3"
                  label="Kvalifikācija:"
                  label-for="input-3"
                  v-if="options"
              >

                <b-form-input list="my-list-id3" v-model="form.qual" disabled></b-form-input>

                <datalist id="my-list-id3">
                  <option v-for="et in options.quals" :id="et">{{ et.text }}</option>
                </datalist>

              </b-form-group>

              <b-form-group label="Pedagoģiskā izglītība:" id="radio-1" v-slot="{ ariaDescribedby }">
                <b-form-radio v-model="form.courses" :aria-describedby="ariaDescribedby" value="1" disabled>Ir nepieciešams</b-form-radio>
                <b-form-radio v-model="form.courses" :aria-describedby="ariaDescribedby" value="0" disabled>Nav nepieciešams</b-form-radio>
              </b-form-group>

            <div class="group-title">
              <h5>Kategorija:</h5>
            </div>

              <b-container>

                <b-form-group label="Kategorija:" id="radio-1" v-slot="{ ariaDescribedby }">
                  <b-form-radio v-model="form.category" :aria-describedby="ariaDescribedby" name="some-radios5" value="0" disabled>darbinieks</b-form-radio>
                  <b-form-radio v-model="form.category" :aria-describedby="ariaDescribedby" name="some-radios5" value="1" disabled>speciālists</b-form-radio>
                  <b-form-radio v-model="form.category" :aria-describedby="ariaDescribedby" name="some-radios5" value="2" disabled>vadītājs</b-form-radio>
                </b-form-group>

              </b-container>

            <div class="group-title">
              <h5>Darbs:</h5>
            </div>

              <b-form-group
                  id="input-group-3"
                  label="Status:"
                  label-for="input-3"
              >

                <b-form-group v-slot="{ ariaDescribedby }">
                  <b-form-radio v-model="form.status" :aria-describedby="ariaDescribedby" value="0" disabled>Pedagoģiskais darbinieks</b-form-radio>
                  <b-form-radio v-model="form.status" :aria-describedby="ariaDescribedby" value="1" disabled>Tehniskais darbinieks</b-form-radio>
                  <b-form-radio v-model="form.status" :aria-describedby="ariaDescribedby" value="2" disabled>Tehniskais/pedagoģiskais darbinieks</b-form-radio>
                </b-form-group>

              </b-form-group>

            <b-container v-if="form.name">

              <b-table striped hover :fields="fields" :items="form.positions">

                <template #cell(start)="row">
                  <span v-if="row.item.start"> {{ new Date(row.item.start).toLocaleDateString() }} </span>
                </template>

                <template #cell(end)="row">
                  <span v-if="row.item.end"> {{ new Date(row.item.end).toLocaleDateString() }} </span>
                </template>

                <template #cell(type)="row">
                  <span> {{ row.item.type === 0 ? 'Pamatdarbs' : row.item.type === 2 ? 'Papildus darbs' : row.item.type === 1 ? 'Blakus darbs' : '' }} </span>
                </template>

                <template #cell(standing)="row">
                  <span v-if="row.item.standing"> {{ formatDateDiff(row.item.standing) }} </span>
                </template>

                <template #cell(head)="row">
                  <span v-if="row.item.head"> {{ row.item.head }} </span>
                </template>

              </b-table>

            </b-container>

            <div class="group-title">
              <h5>Kursi:</h5>
            </div>

              <b-container>

                <b-form-group label="Audzināšanas stundas:" id="radio-1" v-slot="{ ariaDescribedby }">
                  <b-form-radio v-model="form.edu" :aria-describedby="ariaDescribedby" name="some-radios2" value="1" disabled>Ir nepieciešams</b-form-radio>
                  <b-form-radio v-model="form.edu" :aria-describedby="ariaDescribedby" name="some-radios2" value="0" disabled>Nav nepieciešams</b-form-radio>
                </b-form-group>

                <b-form-group label="Bērnu tiesības 8h:" id="radio-1" v-slot="{ ariaDescribedby }">
                  <b-form-radio v-model="form.rights" :aria-describedby="ariaDescribedby" name="some-radios3" value="1" disabled>Ir nepieciešams</b-form-radio>
                  <b-form-radio v-model="form.rights" :aria-describedby="ariaDescribedby" name="some-radios3" value="0" disabled>Nav nepieciešams</b-form-radio>
                </b-form-group>

                <b-form-group label="Kursi 36h:" id="radio-1" v-slot="{ ariaDescribedby }">
                  <b-form-radio v-model="form.short_courses" :aria-describedby="ariaDescribedby" name="some-radios4" value="1" disabled>Ir nepieciešams</b-form-radio>
                  <b-form-radio v-model="form.short_courses" :aria-describedby="ariaDescribedby" name="some-radios4" value="0" disabled>Nav nepieciešams</b-form-radio>
                </b-form-group>

              </b-container>

            <div class="group-title">
              <h5>Komentārs:</h5>
            </div>

              <b-container>

                <b-form-group label="Komentārs:">
                  <b-form-textarea
                      id="textarea"
                      v-model="form.comment"
                      rows="3"
                      max-rows="6"
                      disabled
                  ></b-form-textarea>
                </b-form-group>

              </b-container>

            <div class="group-title">
              <h5>Sistēmas lomas:</h5>
            </div>

              <b-container>

                <b-form-group label="Lomas:" v-slot="{ ariaDescribedby }">
                  <b-form-checkbox-group
                      id="checkbox-group-2"
                      v-model="form.roles"
                      :aria-describedby="ariaDescribedby"
                      name="flavour-2"
                  >
                    <b-form-checkbox :value="r" v-for="r in roles" :key="r.id" disabled>{{ r }}</b-form-checkbox>
                  </b-form-checkbox-group>
                </b-form-group>

              </b-container>

            <b-button v-if="this.$route.params.id !== 'me'" variant="warning" @click="$router.push({ name: 'UserEdit', params: {id: id} })">Labot</b-button>
            <b-button variant="warning" class="ml-3" :to="{ name: 'UserList' }">Atpakaļ</b-button>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
const dateFormat = require('dateformat');

export default {
  name: "Create",
  data() {
    return {
      users: [],
      selectedUser: null,
      created: null,
      id: this.$route.params.id,
      dismissSecs: 5,
      dismissCountDown: 0,
      data: null,
      roles: [],
      fields: [
        {
          key: 'start',
          label: 'Datums no',
          sortable: true
        },
        {
          key: 'end',
          label: 'Datums līdz',
          sortable: true
        },
        {
          key: 'department',
          label: 'Struktūrvienība',
          sortable: true,
        },
        {
          key: 'type',
          label: 'Darba veids',
          sortable: true,
        },
        {
          key: 'post',
          label: 'Amats',
          sortable: true,
        },
        {
          key: 'standing',
          label: 'Stāža',
          sortable: true,
        },
        {
          key: 'head',
          label: 'Tiešais vadītājs',
          sortable: true,
        }
      ],
      form: {
        name: null,
        surname: null,
        personal_code: null,
        birthdate: null,
        email: null,
        roles: [],
        phone: '',
        education_type: '',
        grade: '',
        gender: '',
        category: null,
        qual: '',
        edu: null,
        rights: null,
        courses: null,
        agreement: null,
        agreement_date: null,
        status: null,
        short_courses: null,
        comment: '',
        positions: [

        ],
      },
    }
  },
  created () {
    axios.get('/users').then(response => {
      response.data.data.forEach((user) => {
        this.users.push({ text: user.name + ' ' + user.surname , value: user.id })
      });
    })
  },
  computed: {
    ...mapGetters ({
      User: "Auth/user",
    }),
    ...mapGetters ({
      options: "Options/options",
    })
  },
  methods: {
    ...mapActions ({
      fetchOptions: "Options/fetchOptions"
    }),
    dateFormat(date){
      return (new Date(date)).toLocaleDateString("lv-LV")
    },
    addPost() {
      this.form.positions.push({
        department: '',
        post: '',
        start: '',
        end: '',
        type: '',
      });
    },
    monthDiff(dateFrom, dateTo, standing) {
      if(standing > 0) {
        return standing;
      }

      dateFrom = new Date(dateFrom);
      let end = new Date(dateTo);
      if(dateTo == undefined || end >= new Date()) {
        end = new Date();
      }

      const diffTime = Math.abs(end - dateFrom);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      if(diffDays) {
        return diffDays;
      }

      return 0;
    },
    formatDateDiff(numberOfDays) {
      numberOfDays += 1;
      var years = Math.floor(numberOfDays / 365);
      var months = Math.floor(numberOfDays % 365 / 30);
      var days = Math.floor(numberOfDays % 365 % 30);

      var yearsDisplay = years > 0 ? years + (years == 1 ? " gads " : " gadi ") : "";
      var monthsDisplay = months > 0 ? months + (months == 1 ? " mēnesis " : " mēneši ") : "";
      var daysDisplay = days > 0 ? days + (days == 1 ? " diena" : " dienas") : "";

      return yearsDisplay + monthsDisplay + daysDisplay;
    },
    removePost() {
      this.form.positions.pop();
    },
    onSubmit(event) {
      event.preventDefault()
      axios.put('/users/'+this.$route.params.id, this.form).then(response => {
        this.created = 'success';
        this.getFormData();
      }). catch(response => {
        this.created = 'error';
      })

      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })

      this.dismissCountDown = 5;
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    getFormData() {

      const url = this.$route.params.id === 'me' ?
          `/me` : `/users/${this.$route.params.id}`

      axios.get(url).then(response => {

        this.data = response.data.data


        this.form.name = this.data.name
        this.form.surname = this.data.surname
        this.form.personal_code = this.data.personal_code
        this.form.email = this.data.email
        this.form.phone = this.data.phone
        this.form.gender = this.data.gender
        this.form.roles = this.data.roles
        this.form.edu = this.data.edu
        this.form.courses = this.data.courses
        this.form.short_courses = this.data.short_courses
        this.form.category = this.data.category
        this.form.status = this.data.status
        this.form.rights = this.data.rights
        this.form.comment = this.data.comment
        this.form.education_type = this.data.education_type?.value ?? ''
        this.form.grade = this.data.grade?.value ?? ''
        this.form.qual = this.data.qual?.value ?? ''
        this.form.agreement_date = this.data?.agreement_date ?? ''
        this.form.agreement = this.data?.agreement ?? ''
        this.form.birthdate = this.data?.birthdate ?? ''
        this.roles = this.data.roles

        let positions = [];

        this.data.positions.forEach(p => {
          positions[p.pivot.type] = this.data.positions.filter(e => e.pivot.type === p.pivot.type);
        });

        positions.forEach(async (a, ind) => {
          let months = 0;
          let index = 0;
          let type = -1;
          for(const p of a) {
            index++;
            this.addPost();
            let department = this.options.departments.data.find(d => (d.value === p.pivot.department))
            let len = this.form.positions.length-1;
            this.form.positions[len]['department'] = department?.text
            this.form.positions[len]['head'] = p.pivot?.head || 'nav'
            this.form.positions[len]['post'] = p.name

            this.form.positions[len]['start'] = p.pivot.start
            this.form.positions[len]['end'] = p.pivot.end
            this.form.positions[len]['type'] = p.pivot.type
            this.form.positions[len]['standing'] = this.monthDiff(p.pivot.start, p.pivot.end, p.standing);
            type = p.pivot.type;
            months += this.monthDiff(p.pivot.start, p.pivot.end, p.standing);

          }
          if(a.length > 1 && type == 0) {
            this.addPost();
            let len = this.form.positions.length-1;
            this.form.positions[len]['post'] = 'Kopā:';
            this.form.positions[len]['standing'] = months;
          }
        })

        // this.data.positions.forEach((p, index) => {
        //   if(index > 0) {
        //     this.addPost();
        //   }
        //   let department = this.options.departments.find(d => (d.value === p.pivot.department))
        //   this.form.positions[index]['department'] = department?.text
        //   this.form.positions[index]['post'] = p.name
        //
        //   this.form.positions[index]['start'] = p.pivot.start
        //   this.form.positions[index]['end'] = p.pivot.end
        //   this.form.positions[index]['type'] = p.pivot.type
        //   this.form.positions[index]['standing'] = this.monthDiff(p.pivot.start, p.pivot.end, p.standing);
        // })
      })
    },
  },
  beforeCreate() {
    if(isNaN(this.$route.params.id) && this.$route.params.id !== 'me') {
      this.$router.push({ name: 'UserList' })
    }
  },
  async mounted() {
    await this.fetchOptions();
    await this.getFormData();
  },
}
</script>

<style lang="scss" scoped>

</style>
